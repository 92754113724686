import React from "react"
import Layout from "./components/Layout"
import Pankuzu from "./components/atoms/Pankuzu"
import SEO from "./components/seo"

export default () => (
  <>
    <SEO title="プライバシーポリシー"
  description="プライバシーポリシー"
  image="twitterCard.png"
  lang="ja"
   />
  <Layout>
    <Pankuzu />
    <h2>プライバシーポリシー</h2>
    <h3>当サイトに掲載されている広告について</h3>

    <p>当サイトでは、第三者配信の広告サービス（<a href="http://www.google.com/adsense/start/">Googleアドセンス</a>、<a href="http://www.a8.net/">A8.net</a>、<a href="https://affiliate.amazon.co.jp/">Amazonアソシエイト</a>など）を利用しています。<br />このような広告配信事業者は、ユーザーの興味に応じた商品やサービスの広告を表示するため、当サイトや他サイトへのアクセスに関する情報 『Cookie』(氏名、住所、メール アドレス、電話番号は含まれません) を使用することがあります。<br />またGoogleアドセンスに関して、このプロセスの詳細やこのような情報が広告配信事業者に使用されないようにする方法については、<a href="http://www.google.co.jp/policies/technologies/ads/">こちら</a>をクリックしてください。</p>

    <p>当サイトは、amazon.co.jpを宣伝しリンクすることによってサイトが紹介料を獲得できる手段を提供することを目的に設定されたアフィリエイト宣伝プログラムである、 Amazonアソシエイト・プログラムの参加者です。</p>
    <h2>当サイトが使用しているアクセス解析ツールについて</h2>

    <p>当サイトでは、Googleによるアクセス解析ツール「Googleアナリティクス」を利用しています。<br />このGoogleアナリティクスはトラフィックデータの収集のためにCookieを使用しています。<br />このトラフィックデータは匿名で収集されており、個人を特定するものではありません。<br />この機能はCookieを無効にすることで収集を拒否することが出来ますので、お使いのブラウザの設定をご確認ください。<br />この規約に関して、詳しくは<a href="http://www.google.com/analytics/terms/jp.html">こちら</a>、または<a href="https://www.google.com/intl/ja/policies/privacy/partners/">こちら</a>をクリックしてください。</p>
    <h2>当サイトへのコメントについて</h2>

    <p>当サイトでは、スパム・荒らしへの対応として、コメントの際に使用されたIPアドレスを記録しています。<br />これはブログの標準機能としてサポートされている機能で、スパム・荒らしへの対応以外にこのIPアドレスを使用することはありません。<br />また、メールアドレスとURLの入力に関しては、任意となっております。<br />全てのコメントは管理人であるモトキが事前にその内容を確認し、承認した上での掲載となりますことをあらかじめご了承下さい。<br />加えて、次の各号に掲げる内容を含むコメントは管理人の裁量によって承認せず、削除する事があります。</p>
    <ul>
    <li>特定の自然人または法人を誹謗し、中傷するもの。</li>
    <li>極度にわいせつな内容を含むもの。</li>
    <li>禁制品の取引に関するものや、他者を害する行為の依頼など、法律によって禁止されている物品、行為の依頼や斡旋などに関するもの。</li>
    <li>その他、公序良俗に反し、または管理人によって承認すべきでないと認められるもの。</li>
    </ul>
    <h3>免責事項</h3>

    <p>当サイトに掲載する情報は記事公表時点の正しいものを提供するよう努めております。ただし、提供している情報、リンク先などにより、いかなる損失や損害などの被害が発生しても当サイトでは責任を負いかねますので、ご了承ください。 リンク先の商品は当サイトが販売しているのではなく、各リンク先店舗での販売となります。購入方法、その他お問い合わせは各店舗までご確認ください。商品購入に関するトラブルに関しては、当サイトでは責任を負いかねますので、ご了承ください。</p>
    <h3>当サイトへのリンクについて</h3>

    <p>当サイトは完全リンクフリーです。トップページ、記事等、正規に公開しているページであれば、ご自由にリンクしていただいて問題ありません。ただし、画像への直リンクなどは禁止させていただきます。</p>

  </Layout>
  </>
)